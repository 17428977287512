import React, { useEffect } from "react";
import gsap from "gsap";

export const Preloader: React.FC = () => {
  useEffect(() => {
    const tl = gsap.timeline();

    tl.to(".deco_section", {
      stagger: { amount: 1.5, from: "center" },
      xPercent: -100,
      ease: "Circ.easeInOut",
    }).to(".preloader", {
      xPercent: -100,
      delay: 1,
      ease: "Expo.easeInOut",
      duration: 2,
    });
  }, []);

  return (
    <>
      <div className="preloader">
        <div className="preloader_text">
          <div>DEVELOPER</div>
          <div>CREATOR</div>
          <div>GAMER</div>
        </div>
        <div className="preloader_deco">
          <div className="deco_section"></div>
          <div className="deco_section"></div>
          <div className="deco_section"></div>
        </div>
      </div>
    </>
  );
};
