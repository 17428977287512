import React from "react";
import { Hero } from "../blocks/Hero";
import { Work } from "../blocks/Work";
import {Pitch} from "../blocks/Pitch";
import { Footer } from "../blocks/Footer";

export const Home: React.FC = () => {
  return (
    <div className="wrapper">
      <Hero />
      <Pitch />
      <Work />
      <Footer />
    </div>
  );
};
