import React, { useCallback, useEffect, useState } from "react";
import gsap from "gsap";
import { Link } from "react-router-dom";

export const Header: React.FC = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const animateMenu = useCallback(() => {
    switch (toggleMenu) {
      case true:
        gsap.set(".header", { zIndex: 2 });
        gsap.to("#menu", { opacity: 1, display: "flex" });
        gsap.from(".header_menu__links", {
          stagger: 0.2,
          y: 50,
          rotation: -4,
          opacity: 0,
          duration: 1,
        });
        break;

      default:
        gsap.set(".header", { zIndex: 1 });
        gsap.to("#menu", { opacity: 0, display: "none" });
        break;
    }
  }, [toggleMenu]);

  const handleToggleMenu = () => {
    //setToggleMenu((toggleMenu) => !toggleMenu);
  };

  useEffect(() => {
    animateMenu();
  }, [toggleMenu, animateMenu]);

  return (
    <header className="header">
      <nav className="header_nav">
        <h1>
          <a>
            <span>DHIKHAN</span>
          </a>
        </h1>

        <button
          type="button"
          aria-label="menu"
          aria-controls="menu"
          aria-expanded={toggleMenu}
          onClick={handleToggleMenu}
          className="header_hamburger"
        >
          <span className="vh">
            {/*Provide acceesible button name to screen readers without changing aria-label*/}
            {toggleMenu ? "open" : "close"}
          </span>
        </button>
      </nav>
      <div className="header_menu" id="menu" data-expanded={toggleMenu}>
        <div>
          <a className="header_menu__links">ABOUT</a>
          <a className="header_menu__links">WORKS</a>
          <a className="header_menu__links">WRITING</a>
          <a className="header_menu__links">CONTACT</a>
        </div>
        <div>
          <a className="header_menu__links social">ABOUT</a>
          <a className="header_menu__links social">WORKS</a>
        </div>
      </div>
    </header>
  );
};
