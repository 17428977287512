import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import splitting from "splitting";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import { useSmoothScroll } from "../hooks/useSmoothScroll";
import { ReactComponent as HeroBg } from "../assets/hero.svg";

export const Hero = () => {
  useSmoothScroll();

  const heroTl = useRef(null);

  useEffect(() => {
    /* initialize timeline */
    heroTl.current = gsap.timeline({
      scrollTrigger: {
        trigger: ".hero_bg",
        start: "top top",
        end: "+=1500",
        scrub: 3,
        pin: true,
      },
    });
  }, []);

  useEffect(() => {
    /* sun animation */
    const sun = gsap.timeline();

    sun.to("#bg", { opacity: 1 });
    sun.to("#sun", { attr: { offset: "0.8" } }, 0.0);
    sun.to("#bg_grad stop:nth-child(2)", { attr: { offset: "0.7" } }, 0.0);
    sun.to("#bg_grad stop:nth-child(3)", { attr: { offset: "0.7" } }, 0.0);
    sun.to("#bg_grad stop:nth-child(6)", { attr: { "stop-color": "#FF9171" } });

    sun.to("#sun", { attr: { offset: "0.6" } });
    sun.to("#sun", { attr: { "stop-color": "#fff" } });
    sun.to("#bg_grad stop:nth-child(1)", { attr: { "stop-color": "#fff" } });
    sun.to("#bg_grad stop:nth-child(2)", { attr: { "stop-color": "#fff" } });
    sun.to("#bg_grad stop:nth-child(6)", { attr: { "stop-color": "#693f6e" } });

    heroTl.current.add(sun, 0.5);
  }, []);

  useEffect(() => {
    /* Clouds Animation */
    const clouds = gsap.timeline({ defaults: { duration: 2 } });

    clouds.to("[id*=clouds]", { y: 50 }, 0);
    clouds.to("#cloud1", { x: 500 }, 0);
    clouds.to("#cloud2", { y: 200 }, 0);
    clouds.to("#cloud3", { x: -1000 }, 0);
    clouds.to("#cloud4", { x: -700, y: 25 }, 0);
    clouds.set("[id*=clouds]", { autoAlpha: 0 });

    heroTl.current.add(clouds, 0);
  }, []);

  useEffect(() => {
    /* Day animations */
    const day = gsap.timeline({ delay: 2.8 });

    day.to("#day_bush", { yPercent: 100 });
    day.to("#day_hills", {
      yPercent: 100,
      ease: "Power0.easeIn",
      duration: 1.1,
    });

    heroTl.current.add(day, 0);
  }, []);

  useEffect(() => {
    /* Night animations */
    const night = gsap.timeline();

    night.from("[id*=night_hill]", {
      yPercent: 100,
      stagger: { amount: 0.3, from: "center" },
      ease: "Slow.easeIn",
    });

    night.from(
      "#night_bush1",
      {
        yPercent: 100,
        ease: "Power0.easeIn",
        duration: 2,
      },
      0.1
    );

    //animating out of bush
    night.fromTo(
      "#bats1",
      {
        yPercent: 120,
        xPercent: -100,
      },
      { opacity: 1, yPercent: -100, xPercent: 200, duration: 3 }
    );

    //making bats fly
    night.to(
      "#bats1 path",
      {
        scaleX: 0.5,
        repeat: 20,
        duration: 0.15,
        transformOrigin: "50% 50%",
      },
      0.3
    );

    heroTl.current.add(night);
  }, []);

  useEffect(() => {
    //Animate the Hero Intro
    const getTarget = (targetClass) => {
      return document.querySelector(`.${targetClass}`);
    };

    const topText = splitting({ target: getTarget("top_text"), by: "chars" });
    const middleText = splitting({
      target: getTarget("middle_text"),
      by: "chars",
    });
    const bottomText = splitting({
      target: getTarget("bottom_text"),
      by: "chars",
    });

    gsap.from([topText[0].chars, middleText[0].chars, bottomText[0].chars], {
      delay: 4.3,
      y: 20,
      opacity: 0,
      stagger: 0.1,
    });
  }, []);

  return (
    <div className="hero">
      <div className="hero_bg">
        <HeroBg />

        <div className="hero_intro">
          <h2 className="a">
            <span className="top_text">Welcome to</span> <br />
            <span className="middle_text">Dhikhan's</span> <br />
            <span className="bottom_text">Playground</span> <br />
          </h2>
        </div>
      </div>
    </div>
  );
};
