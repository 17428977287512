import React, { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import { Draggable } from "gsap/Draggable";

import { pitch } from "../utilities";

export const Pitch = () => {
  const [count, setCount] = useState(0);
  const rotateAnglesRef = useRef([20, 16, 12, 8, 4, 0]);

  useEffect(() => {
    gsap.registerPlugin(Draggable);
    const cards: Array<Element> = gsap.utils.toArray(".pitch_slide");

    gsap.from(cards, {
      autoAlpha: 0,
      scale: 0.4,
      stagger: 0.08,
      force3D: true,
      ease: "Power4.easeInOut",
      scrollTrigger: {
        trigger: ".pitch",
        start: "center 85%",
        scrub: false,
      },
    });

    Draggable.create(cards, {
      type: "x",
      force3D: true,
      onDragEnd: handleDragDirection,
      onDragEndParams: [cards, setCount, rotateAnglesRef],
    });
  }, []);

  useEffect(() => {
    const cards: Array<Element> = gsap.utils.toArray(".pitch_slide");

    if (count === cards.length) {
      setCount(0); //Reset the count

      //Return cards to original position
      gsap.to(cards, {
        duration: 0.3,
        delay: 0.5,
        x: 0,
        stagger: 0.06,
        ease: "Power4.easeOut",
        force3D: true,
      });

      //Reset rotation and stacking order
      cards.forEach((card, index) => {
        gsap.to(card, {
          zIndex: index * 2,
          rotate: rotateAnglesRef.current[index],
        });
        rotateAnglesRef.current = [20, 16, 12, 8, 4, 0];
      });
    }
  }, [count]);

  useEffect(() => {
    const slides: Array<Element> = gsap.utils.toArray(".pitch_slide");

    slides.reverse().forEach((slide, index) => {
      gsap.set(slide, {
        transform: `rotate(${index * 4}deg)`,
      });
    });
  }, []);

  return (
    <div className="pitch">
      <div>
        <h3 className="pitch_header">
          <span>A bit</span> <br />
          <span className="work_deco">
            <span>about me</span>
            {/*<svg
              fill="none"
              preserveAspectRatio="none"
              viewBox="0 0 459 315"
              xmlns="http://www.w3.org/2000/svg"
              className="work_svg"
            >
              <path
                d="m37.9827 67.401c62.6553-2.3532 192.4733 14.5084 225.7033 23.4123 33.229 8.9039 171.913 46.4967 172.688 91.1327.775 44.637-98.087 87.949-209.121 77.685s-199.4422-61.075-200.151-105.026c-.7089-43.951 71.6094-82.6545 164.085-94.0772 92.476-11.4227 253.793 31.4917 204.039 118.4652"
                stroke="#d73234"
                strokeWidth="3"
                //style="stroke-dashoffset: 0; stroke-dasharray: none;"
              ></path>
            </svg>*/}
          </span>
        </h3>
      </div>

      <div className="pitch_slides">
        {pitch.map((slide, index) => (
          <div
            className="pitch_slide"
            style={{
              backgroundColor: slide.color,
            }}
            key={slide.text + index}
          >
            {slide.text.split("|").map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

function handleDragDirection(
  cards: Array<Element>,
  setCount: React.Dispatch<React.SetStateAction<number>>,
  rotateAnglesRef: React.MutableRefObject<number[]>
) {
  const direction = this.getDirection(); //drag direction
  console.log(this);

  gsap.to(this.target, {
    duration: 0.3,
    x: direction.includes("right") ? 1000 : -1000,
    ease: "Power0.easeInOut",
  });

  setCount((count) => count + 1); //track number of drags

  //update rotate values
  cards.forEach((card, index) => {
    gsap.to(card, {
      rotate: rotateAnglesRef.current[index] - 4,
    });

    rotateAnglesRef.current[index] = rotateAnglesRef.current[index] - 4;
  });
}
