import React from "react";
import gmail from "../assets/gmail.svg";

export const Footer = () => {
  return (
    <footer className=".footer_construct">
      <div style={{ position: "relative", pointerEvents: "none" }}>
        <p
          style={{
            position: "relative",
            zIndex: 1,
            fontSize: "2em",
          }}
        >
          Let's collaborate & <br /> create magic.
        </p>

        <a href="mailto:christianotu.d@gmail.com" className="footer_deco">
          Contact Me
        </a>
      </div>

      <div className="footer_disclaimer">
        <p>
          Psstt! This site is old and not actively maintained☹️. Please check{" "}
          <a
            href="https://github.com/kriszzy01"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
        </p>
      </div>
    </footer>
  );
};
