import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import LocomotiveScroll from "locomotive-scroll";

export const useSmoothScroll = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({ scroller: ".wrapper", scrub: true }); //set the new scroller

    const locoScroll = new LocomotiveScroll({
      el: document.querySelector(".wrapper"),
      smooth: true,
    });
    locoScroll.on("scroll", ScrollTrigger.update);

    ScrollTrigger.scrollerProxy(".wrapper", {
      scrollTop(value) {
        return arguments.length
          ? locoScroll.scrollTo(value, 0, 0) //set scrollTop
          : locoScroll.scroll.instance.scroll.y; //get scrollTop
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: document.querySelector(".wrapper").style.transform //Usefull for mobile devices
        ? "transform"
        : "fixed",
    });

    ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
    ScrollTrigger.refresh();
  }, []);
};
