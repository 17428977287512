import gaming from "../assets/gaming.svg";
import camera from "../assets/camera.svg";
import food from "../assets/food.svg";
import statistics from "../assets/statistics.svg";
import music from "../assets/music.svg";

export const projects = [
  {
    bgColor: "#fff",
    bgImage: gaming,
    projectCount: 1,
    title: "Klic",
    details:
      "Klic is a video streaming platform for gamers | Hosted ZWC3 & ZWI2 events with over 700 live streamers.",
    link: "https://klic.gg/",
  },
  {
    bgColor: "#d6c1fd",
    bgImage: food,
    projectCount: 2,
    title: "Homedotly",
    details:
      "Homedotly is a virtual food marketplace | Facilitates trading of a vast variety of food products.",
    link: "https://homedotly.co.uk/",
  },
  {
    bgColor: "#fff",
    projectCount: 3,
    bgImage: statistics,
    title: "Covid-19 Dashboard",
    details:
      "Coronavirus Dashboard which provides live data from John's Hopkins University | Data visiualization using maps, chats and tables.",
    link: "https://kriszzy01.github.io/coronavirus-dashboard/",
  },
  {
    bgColor: "#F3EEE8",
    bgImage: music,
    projectCount: 4,
    title: "Kijie",
    details:
      "Kijie is a project management tool | Features similar capabilities as Jira",
    link: "https://kijie.netlify.app/",
  },
  {
    bgColor: "white",
    bgImage: statistics,
    projectCount: 5,
    title: "AX Hotels",
    details: "AX Hotels",
    link: "https://ax-hotels.vercel.app/",
  },

  {
    bgColor: "#d6c1fd",
    bgImage: camera,
    projectCount: 6,
    title: "Rafael Bojar",
    details:
      "Rebuild of Rafael Bojar's website | Original website done by Zhanya Rynzhuk",
    link: "https://kriszzy01.github.io/RefalBojar-rebuild/",
  },

  {
    bgColor: "#F3EEE8",
    bgImage: music,
    projectCount: 7,
    title: "Soundman Music",
    details:
      "Minimalistic music player | A limited range of handpicked songs using a simple but sleek interface.",
    link: "https://kriszzy01.github.io/soundman/",
  },
  {
    bgColor: "#d6c1fd",
    bgImage: music,
    projectCount: 8,
    title: "Unit Converter",
    details: "Convert your S.I. Units Seamlessly",
    link: "https://kriszzy01.github.io/SI-Unit-Converter/",
  },
];

export const pitch = [
  {
    text: "Psstt! | This site is old and not actively maintained☹️",
    color: "#074e4a",
  },
  {
    text: "My mission (impossible) | is to make the web less boring",
    color: "#891634",
  },

  {
    text: "I'm big on testing! Unit, Integration, E2E? LGTM🚀! ",
    color: "cornflowerblue",
  },
  {
    text: "I build accessible, performant, and scalable applications!",
    color: "#735129",
  },
  {
    text: "Frontend Engineer, | Technical Writer",
    color: "darkolivegreen",
  },
  {
    text: "Hi, | I'm  | Christian | Dhikhan",
    color: "#bb5b0b",
  },
];
