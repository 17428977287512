import React, { useEffect, useRef } from "react";
import gsap from "gsap";

interface ProjectProps {
  title?: string;
  details: string;
  bgImage?: any;
  bgColor?: string;
  link?: string;
}

export const Project: React.FC<ProjectProps> = ({
  bgColor,
  title,
  details,
  bgImage,
  link,
}) => {
  const projectRef = useRef<HTMLDivElement>(null!);
  const detailsArray = details.split("|");

  //const cardTl = gsap.timeline({ paused: true, defaults: { duration: 0.2 } });

  const handleHoverOver = (event: React.MouseEvent<HTMLDivElement>) => {
    const cards = projectRef.current.querySelectorAll(".project_card");
    //cardTl.to(cards[0], { rotate: -10, transformOrigin: "right center" });
    //cardTl.to(cards[1], { rotate: 10, transformOrigin: "right center" }, 0);
    //cardTl.play();
    gsap.to([cards, ".project_text h4"], { scale: 1.025 });
  };

  const handleHoverOut = (event: React.MouseEvent<HTMLDivElement>) => {
    const cards = projectRef.current.querySelectorAll(".project_card");
    gsap.to([cards, ".project_text h4"], { scale: 1 });
  };

  useEffect(() => {
    const projectBg = projectRef.current.querySelector(
      ".project_bg__round"
    ) as Element;
    const cards = projectRef.current.querySelectorAll(".project_card");
    const text = projectRef.current.querySelector(".project_text") as Element;

    //Tween for animating background
    gsap.to(projectBg, {
      scrollTrigger: {
        trigger: projectBg,
        start: "top top",
        end: "20% top",
      },
      borderRadius: 10,
      duration: 0.3,
      ease: "Sine.easeInOut",
    });

    //Tween for animating first card
    const tl = gsap.timeline();
    tl.fromTo(
      cards[0],
      { yPercent: 30, rotation: 5 },
      {
        scrollTrigger: {
          trigger: cards[0],
          start: "top bottom",
        },
        yPercent: -15,
        rotation: -5,
      }
    );

    //Tween for animating second card
    tl.to(
      cards[1],
      {
        scrollTrigger: {
          trigger: cards[1],
          start: "top bottom",
        },
        yPercent: -15,
        rotation: 7,
      },
      0
    );

    //Animate opacity of text section
    tl.to(text, {
      scrollTrigger: {
        trigger: text,
        start: "top top",
        end: "bottom 5%",
      },
      opacity: 0,
    });
  }, []);

  return (
    <div className="project" ref={projectRef}>
      <div className="project_bg__round" style={{ backgroundColor: bgColor }} />
      <div
        className="project_bg__square"
        style={{ backgroundColor: bgColor }}
      />
      <div className="project_detail">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="project_link"
        >
          <span className="vh">{title}</span>
          <div
            className="project_card"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <div
              className="project_media__wrapper"
              onMouseEnter={handleHoverOver}
              onMouseLeave={handleHoverOut}
            >
              <div className="project_card__media"></div>
            </div>
          </div>
          <div
            className="project_card"
            onMouseEnter={handleHoverOver}
            onMouseLeave={handleHoverOut}
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <div className="project_media__wrapper">
              <div className="project_card__media"></div>
            </div>
          </div>
        </a>
        <div className="project_text">
          <h4 onMouseEnter={handleHoverOver} onMouseLeave={handleHoverOut}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </h4>
          <p>
            {detailsArray.map((detail, index) => (
              <span key={`${detail.slice(0, 4)}${index}`}>
                {detail}
                <br />
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};
