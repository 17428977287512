import React from "react";
import "./styles/style.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Preloader } from "./components/Preloader";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Header } from "./blocks/Header";
import { Footer } from "./blocks/Footer";

const App: React.FC = () => {
  return (
    <>
      <Preloader />
      <Header />
      <Home />
    </>
  );
};

export default App;
